import Visa from '../assets/images/iconos/visa.svg'
import Mastercard from '../assets/images/iconos/mastercard.svg'
import Maestro from '../assets/images/iconos/maestro.svg'
// import Cabal from '../assets/images/iconos/cabal.svg'
import Amex from '../assets/images/iconos/amex.svg'
import Mercadopago from '../assets/images/iconos/mercadopago.svg'
import Cuentadni from '../assets/images/iconos/cuentadni.svg'
import Modo from '../assets/images/iconos/modologo.svg'

export const  mediosDePago = [
    Visa,
    Mastercard,
    // Cabal,
    Amex,
    Maestro,
    Mercadopago,
    Cuentadni,
    Modo
];