export const indCats = [
    {
        name: 'Camisas',
        descr: '',
    },
    {
        name: 'Pantalones',
        descr: '',
    },
    {
        name: 'Chombas',
        descr: '',
    },
    {
        name: 'Remeras',
        descr: '',
    },
    {
        name: 'Jeans',
        descr: '',
    },
    {
        name: 'Sweaters',
        descr: '',
    },
    {
        name: 'Buzos',
        descr: '',
    },
    // {
    //     name: 'Camperas',
    //     descr: '',
    // },
    // {
    //     name: 'Ambos',
    //     descr: '',
    // },
    {
        name: 'Accesorios',
        descr: '',
    },
    // {
    //     name: '',
    //     descr: '',
    // },
]