const links = [
    {
        url: "portada",
        name: "Inicio"
    },
    {
        url: "indumentaria",
        name: "Indumentaria"
    },
    {
        url: "contacto",
        name: "Contacto"
    }
]
export default links;